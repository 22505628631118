/* eslint-disable */
import { create, all } from 'mathjs'

var precision = 15
const config = {
  // Default type of number
  // Available options: 'number' (default), 'BigNumber', or 'Fraction'
  number: 'BigNumber',

  // Number of significant digits for BigNumbers
  precision: precision
}
const math = create(all, config)

Number.prototype.add = function(v) {
  ///<summary>精确计算加法。语法：number1.add(v)</summary>
  ///<param name="v" type="number">操作数。</param>
  ///<returns type="number">计算结果。</returns>
  return Number(math.format(math.add(v, this), precision))
}

Number.prototype.sub = function(v) {
  ///<summary>精确计算减法。语法：number1.sub(v)</summary>
  ///<param name="v" type="number">操作数。</param>
  ///<returns type="number">计算结果。</returns>
  return Number(math.format(math.add(-v, this), precision))
}

Number.prototype.mul = function(v) {
  ///<summary>精确计算乘法。语法：number1.mul(v)</summary>
  ///<param name="v" type="number">操作数。</param>
  ///<returns type="number">计算结果。</returns>
  return Number(math.format(math.multiply(this, v), precision))
}

Number.prototype.div = function(v) {
  ///<summary>精确计算除法。语法：number1.div(v)</summary>
  ///<param name="v" type="number">操作数。</param>
  ///<returns type="number">计算结果。</returns>
  return Number(math.format(math.divide(this, v), precision))
}

function formatNumber(num) {
  if (isNaN(num)) {
    throw new TypeError('num is not a number')
  }

  return ('' + num).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
}

Number.prototype.formatNumber = function() {
  return formatNumber(this)
}
