<template>
  <div class="no-data-area">
    <img class="icon-no-data" :src="iconAddress" alt="暂无数据">
    <p class="no-data-title">暂无数据</p>
  </div>
</template>

<script>
export default {
  name: 'NoData',
  props: {
    // address list message internet coupon data
    type: {
      type: String,
      default: 'data'
    }

  },
  data() {
    return {

    }
  },
  computed: {
    iconAddress() {
      return require(`./images/icon_no_${this.type}.svg`)
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.no-data-area {
  width: 160px;
  height:50vh;
  margin: auto;
  margin-top: 20px;
}
.icon-no-data {
  display: block;
  width: 160px;
  height: 160px;
  margin: auto;
}
.no-data-title {
  font-size: 13px;
  color: #5e6065;
  text-align: center;
}
</style>
