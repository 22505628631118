<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
  },
  mounted() {
    if (["development", 'test' ].includes(process.env.NODE_ENV) ) {
      setTimeout(() => {
        document.getElementById('__vconsole').style.display = 'block'
      }, 1000);
    }
  }

}
</script>

<style>
</style>
