<!--图片验证码组件-->
<template>
</template>
<script>
export default {
  name: `AliyunVerificationPopup`,
  data() {
    return {
      // 图片内容
      promptShow:false,
      // 显示图片
      src:null,
      // 输入code
      captcha:null,
      // 图片key
      imgKey:null,
    };
  },
  created() {

  },
  methods: {
    /**
    * @description - 获取图片验证码
    * @return void
    **/
    async getImgCaptcha() {
      let that = this;
      initAliyunCaptcha({
        SceneId: 'b3jgzbwx', // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
        prefix: '1aaj55', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
        mode: 'popup', // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
        element: '#captcha-element', // 页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
        button: '#smsButton', // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
        captchaVerifyCallback: that.captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
        onBizResultCallback: that.onBizResultCallback, // 业务请求结果回调函数，无需修改
        getInstance: this.getInstance, // 绑定验证码实例函数，无需修改
        slideStyle: {
          width: 360,
          height: 40,
        }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px。
      });
    },
    /**
    * @description - 业务请求(带验证码校验)回调函数，
    * @return void
    **/
    captchaVerifyCallback(e) {
      this.$emit('GraphicVerification',e,true,true);
      const verifyResult = {
        captchaResult: true, // 验证码验证是否通过，boolean类型，必选
        bizResult: true, // 业务验证是否通过，boolean类型，可选；若为无业务验证结果的场景，bizResult可以为空
      };
      return verifyResult;
    },
    /**
    * @description - 业务请求结果回调函数
    * @return void
    **/
    onBizResultCallback(e) {
      console.log(e);
    },
    /**
    * @description - 获取图片内容
    * @return void
    **/
    getCode() {
      this.getImgCaptcha();
    }
  },
};
</script>

<style lang="scss">
#aliyunCaptcha-window-popup {
  font-size: 14px;
}
</style>