<!--图片验证码组件-->

<template>
  <prompt
    v-model="promptShow"
    title="安全提示"
    desc=""
    :oneBtn="true"
    oneBtnText="提交"
    @oneBtnConfirm="onConfirm"
  >
    <div class="item" v-show="false">&nbsp;</div>
    <div class="item">
      <img class="simg" :src="src" @click="getImgCaptcha()">
    </div>
    <div class="item">
      <van-cell-group class="input-area account">
        <van-field
          v-model="code"
          maxlength="10"
          label=""
          placeholder="请输入图形码"
          center
          clearable
        />
      </van-cell-group>
    </div>
  </prompt>
</template>

<script>
import Prompt from '@/components/prompt';
export default {
  name: `GraphicVerificationPopup`,
  props: {
  },
  components: {
    Prompt
  },
  data() {
    return {
      // 图片内容
      promptShow:false,
      // 显示图片
      src:null,
      // 输入code
      code:null,
      // 图片key
      imgKey:null,
    };
  },
  created() {

  },
  methods: {
    /**
    * @description - 获取图片验证码
    * @return void
    **/
    async getImgCaptcha() {
      const captcha = await this.$api.user.login_img_captcha();
      this.src = captcha?.data?.data?.img;
      this.imgKey = captcha?.data?.data?.key;
    },
    /**
    * @description - 校验图形吗码
    * @return void
    **/
    onConfirm() {
      if (!this.code) {
        this.$toast(`请输入图形验证码！`);
        return;
      }
      this.$emit('GraphicVerification',this.code,this.imgKey,true);
    },
    /**
    * @description - 关闭弹窗
    * @return void
    **/
    closedCaptcha() {
      this.promptShow = false;
    },
    /**
    * @description - 获取图片内容
    * @return void
    **/
    getCode() {
      this.getImgCaptcha();
      this.promptShow = true;
      this.code = '';
    }
  },
};
</script>

<style lang="scss" scoped>
  .item {
    background: #F5F6FA;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 8px;
    .simg{
      width: 120px;
      height: auto;
      vertical-align: middle;
    }

  }
</style>