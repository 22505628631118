import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from '@/vuex/store.js'

import '@/styles/index.scss'

import JSEncrypt from 'jsencrypt'
import * as api from './http'
api.default.install(Vue)
import Vant from 'vant'
import 'vant/lib/index.less'

const VUE_APP_CONFIG_RSA_PUBKEY =
	'-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQChYML6XTG+8XzpDIEwfOPj7GScev47/xqBJqqrSLhlhLcejls6WNvuUXASehYJTQ+5VzGdhKr0Va0x/s5wsL6lSbo+dhnVAX7TKZMKEZEwVZDLTlzqX77/Mjaz3T9pMNF0SIxZwUTk+wEuUbUTAbYac9eqe0/KKG2+zvEGU7O6bQIDAQAB-----END PUBLIC KEY-----'

import Navbar from '@/components/navbar.vue'
import Prompt from '@/components/prompt.vue'
import Smsbutton from '@/components/smsbutton.vue'
import GraphicVerification from '@/components/GraphicVerificationPopup.vue';
import '@/icons'

import commonMixin from '@/mixins/common'
import NoData from '@/components/noData.vue'
import '@/utils/number'

Vue.component('Navbar', Navbar)
Vue.component('Prompt', Prompt)
Vue.component('Smsbutton', Smsbutton)
Vue.component('NoData', NoData)
Vue.component(`GraphicVerification`, GraphicVerification);

Vue.use(Vant)
Vue.mixin(commonMixin)
Vue.prototype.$encrypt = function(str) {
  const encryptStr = new JSEncrypt()
  encryptStr.setPublicKey(VUE_APP_CONFIG_RSA_PUBKEY) // 设置 加密公钥
  const data = encryptStr.encrypt(str.toString()) // 进行加密
  return data
}
// 解密
Vue.prototype.$decrypt = function(str) {
  const decryptStr = new JSEncrypt()
  decryptStr.setPublicKey(VUE_APP_CONFIG_RSA_PUBKEY) // 设置 加密公钥
  const data = decryptStr.decrypt(str.toString()) // 进行加密
  return data
}

Vue.config.productionTip = false

new Vue({
  router,
  // store,
  render: (h) => h(App)
}).$mount('#app')
