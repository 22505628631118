<template>
  <div>
    <van-nav-bar
      class="navbar"
      :class="classObj[type]"
      :title="title"
      :border="false"
      :fixed="true"
      left-text=""
      right-text=""
      :safe-area-inset-top="true"
      v-bind="$attrs"
      v-on="$listeners"
      @click-left="onClickLeft"
    >
      <template #left>
        <img v-if="allowBack && whiteArrowArr.indexOf(type) !== -1" class="arrow" src="@/assets/common/icon_arrow_left_white.svg" alt="">
        <img v-if="allowBack && blackArrowArr.indexOf(type) !== -1" class="arrow" src="@/assets/common/icon_arrow_left_black.svg" alt="">
      </template>
      <template #title>
        <slot name="title" />
      </template>
      <template #right>
        <slot name="right" />
      </template>
    </van-nav-bar>
    <div class="placeholder" />
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  props: {
    type: {
      type: String,
      default: '1'
    },
    title: {
      type: String,
      default: ''
    },
    allowBack: {
      type: Boolean,
      default: false
    }
    // hasBorder: {
    //     type: Boolean,
    //     default: true
    // }
  },
  data() {
    return {
      classObj: {
        '1': 'white-theme',
        '2': 'blue-theme',
        '3': 'gray-theme',
        '4': 'deep-blue-theme',
        '5': 'orange-theme',
        '6': 'sky-orange-theme',
        '7': 'sky-blue-theme'
      },
      whiteArrowArr: ['2', '4', '5'],
      blackArrowArr: ['1', '3', '6', '7']
    }
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
  .van-nav-bar {
    height: 44PX;
  }
  .arrow {
      width: 22PX;
      height: 22PX;
  }
  .navbar {
    padding-top: var(--status-bar-height);
    width: 100%;
    z-index: 2000;
  }
  .placeholder {
    padding-top: var(--status-bar-height);
    height: 44PX;
  }
  .white-theme {
    background-color: #fff;
    ::v-deep .van-nav-bar__title {
      color: #323233;
    }
  }
  .blue-theme {
    background-color: #2E5BFD;
    ::v-deep .van-nav-bar__title {
      color: #fff;
    }
  }
  .deep-blue-theme {
    background-color: #2948FF;
    ::v-deep .van-nav-bar__title {
      color: #fff;
    }
  }
  .gray-theme {
    background-color: #F3F5F9;
    ::v-deep .van-nav-bar__title {
      color: #323233;
    }
  }
  .orange-theme {
    background-color: #f24a39;
    ::v-deep .van-nav-bar__title {
      color: #fff;
    }
  }
.sky-orange-theme{
  background-color: rgb(255,204,184);
  ::v-deep .van-nav-bar__title {
    color: #323233;
  }
}
.sky-blue-theme{
  background-color: #D9E8FD;
  ::v-deep .van-nav-bar__title {
    color: #323233;
  }
}

</style>
