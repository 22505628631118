import request from '../request'

export function card_order_create(data) {
  return request({
    url: '/va/outside/bank/create',
    method: 'post',
    data
  })
}

// 办卡-银行中心
export function bank_center(params) {
  return request({
    url: '/va/outside/bank/center',
    method: 'get',
    params
  })
}

// app内启动广告-轮播图查询
export function operate_advertisement_list(params) {
  return request({
    url: `/operate/advertisement/list`,
    method: 'get',
    params
  })
}

// 贷款-贷款中心
export function loan_center(params) {
  return request({
    url: '/va/outside/loan/center',
    method: 'get',
    params
  })
}

// 贷款产品详情
export function loan_detail(params) {
  return request({
    url: '/va/outside/loan/detail',
    method: 'get',
    params
  })
}

// 贷款订单-申请
export function loan_order_create(data) {
  return request({
    url: '/va/outside/loan/create',
    method: 'post',
    data
  })
}



// 获取黑名单
export function outside_blacklist_get(params) {
	return request({
		url: '/va/outside/blacklist/get',
		method: 'get',
		params,
	})
}

//------------------------------------zy02------------------------------
// 银行中心
export function zy_card_center(params) {
  return request({
		url: `/va/outside/bank/center/V2`,
		method: 'get',
		params,
	})
}


// 卡片列表
export function zy_card_list(params) {
  return request({
		url: `/va/outside/bank/card/list`,
		method: 'get',
		params,
	})
}

// 卡片申请
export function zy_card_createV2(data) {
	return request({
		url: `/va/outside/bank/createV2`,
		method: 'post',
		data,
	})
}


//--------------------贷款-----------

// 贷款中心
export function zy_loan_app_center(params) {
  return request({
		url: `/va/outside/loan/centerV2`,
		method: 'get',
		params,
	})
}

// 贷款详情
export function zy_loan_app_detail(params) {
  return request({
		url: `/va/outside/loan/detailV2`,
		method: 'get',
		params,
	})
}

// 贷款申请
export function zy_loan_createV2(data) {
	return request({
		url: `/va/outside/loan/createV2`,
		method: 'post',
		data,
	})
}

// 获取卡片详情信息
export function bank_card_detail(params) {
  return request({
    url: `/va/outside/bank/card/detail`,
    method: `get`,
    params
  });
}

